// Libs
import { PageName } from "@constants"
// Components
import Head from "next/head"
import Login from "@containers/login"
import { t } from "i18next"
// Styles

const LoginPage = () => {
  return (
    <>
      <Head>
        <title>{t("TXT_Meta_Head_Title_Login")}</title>
        <meta name="Description" content={t("TXT_Meta_Description_Title_Login")} />
      </Head>
      <Login />
    </>
  )
}

LoginPage.displayName = PageName.login

export default LoginPage
