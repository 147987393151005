import { useState } from "react"
import { useDispatch } from "react-redux"
import { t } from "i18next"
import Router from "next/router"
import { postOTP } from "@slices/auth"
import { getAdminById } from "@slices/admins"
import { setCookieToken } from "@constants"

import { InputBase, InputPassword } from "@components/inputs"
import { ImgBase } from "@components/images"
import { VSpace, Link, Text } from "@layout"
import { ButtonBase } from "@components/buttons"
import { Modal } from "@components/modals"

const Login = () => {
  const dispatch = useDispatch()
  const [values, setValues] = useState({ email: "", password: "" })
  const [errorMessage, setErrorMessage] = useState()

  const handleLoginClick = () => {
    dispatch(postOTP(values))
      .unwrap()
      .then(async (res) => {
        if (res?.accessToken) {
          // schsa office ip login
          setCookieToken(res.accessToken)
          await dispatch(getAdminById())
            .unwrap()
            .then(() => {
              Router.push({ pathname: "/" })
            })
        } else {
          Router.push({ pathname: "/login", query: { state: "otp" } })
        }
      })
      .catch(({ data }) => {
        setErrorMessage(data?.message ?? "Error")
      })
  }
  const handleChange = (type, event) => {
    if (type === "press") {
      if (event.key === "Enter") handleLoginClick()
    } else {
      setValues({ ...values, [type]: event.target.value })
    }
  }

  const modalConfig = {
    buttonList: [{ label: t("TEXT_COMMON_confirm"), onClick: () => setErrorMessage() }],
    onClose: () => setErrorMessage(),
    open: Boolean(errorMessage)
  }

  return (
    <>
      <ImgBase src="new-logo-1-1" alt="logo" width={204} height={102} />
      <VSpace length={88} />
      <InputBase
        value={values.email}
        placeholder={t("LOGIN_user_name")}
        onChange={(event) => handleChange("email", event)}
      />
      <VSpace length={18} />
      <InputPassword
        value={values.password}
        placeholder={t("LOGIN_password")}
        onChange={(event) => handleChange("password", event)}
        onKeyDown={(event) => handleChange("press", event)}
      />
      <VSpace length={18} />
      <Link href={{ pathname: "/login", query: { state: "forget" } }}>{t("LOGIN_forget_password")}</Link>
      <VSpace length={26} />
      <ButtonBase
        label={t("LOGIN_login")}
        size="21px"
        weight="500"
        fullWidth
        border="transparent"
        onClick={handleLoginClick}
      />

      <Modal {...modalConfig}>
        <Text size="18px" weight="400" margin="auto 0">
          {t(errorMessage)}
        </Text>
      </Modal>
    </>
  )
}

export default Login
