import React from "react"
import { useRouter } from "next/router"

import { Container } from "./index.style"
import { Column } from "@layout"
import Login from "./login"
import ForgetPassword from "./forget-password"
import OTP from "./otp"

const LoginPage = () => {
  const router = useRouter()
  const { query } = router

  const Component = ({ state }) => {
    switch (state) {
      case "forget":
        return <ForgetPassword />
      case "otp":
        return <OTP />
      default:
        return <Login />
    }
  }
  return (
    <Container>
      <Column sx={{ alignItems: "center", maxWidth: "392px", width: "100%" }}>{Component(query)}</Column>
    </Container>
  )
}

export default LoginPage
