import React, { useState } from "react"
import { t } from "i18next"
import { useDispatch } from "react-redux"
import { getForgetPassword } from "@slices/auth"
import Router from "next/router"
import appENV from "@utils/appENV"

import { InputBase, InputWrapper } from "@components/inputs"
import { ImgBase } from "@components/images"
import { VSpace, HSpace, Text, Row } from "@layout"
import { ButtonBase } from "@components/buttons"
import { Modal } from "@components/modals"

const ForgetPassword = () => {
  const [email, setEmail] = useState("")
  const [sent, setSent] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()

  const handleChange = ({ target }) => {
    setEmail(target.value)
  }

  const handleBackToLoginClick = ({ target }) => {
    Router.push({ pathname: "/login" })
  }

  const handleForgetPasswordClick = () => {
    dispatch(getForgetPassword({ email }))
      .unwrap()
      .then((res) => {
        setSent(true)
        if (appENV === "local") {
          // for local testing without sent email
          setTimeout(() => {
            Router.push(res.url.replace("https://ehs-dev.redso.com.hk/cms", "http://localhost:4060"))
          }, 3000)
        }
      })
      .catch(() => handleModalOpen(true))
  }

  const handleModalClose = () => setOpenModal(false)
  const handleModalOpen = () => setOpenModal(true)

  const modalConfig = {
    buttonList: [{ label: t("TEXT_COMMON_confirm"), onClick: handleModalClose }],
    onClose: handleModalClose,
    open: openModal
  }
  return (
    <>
      {sent ? (
        <>
          <ImgBase src="bx-bx-mail-send" alt="mail send" width={200} />
          <VSpace length={50} />
          <Text size="16px" weight="normal" whitespace="nowrap">
            {t("LOGIN_email_resend")}
          </Text>
          <VSpace length={30} />
          <ButtonBase
            label={t("LOGIN_back_to_login_page")}
            size="21px"
            weight="500"
            fullWidth={true}
            border="transparent"
            onClick={handleBackToLoginClick}
          />
        </>
      ) : (
        <>
          <Row>
            <ImgBase src="ant-design-question-circle-outlined" alt="forget password" width={66} height={66} />
            <HSpace length={14} />
            <Text size="28px" weight="500">
              {t("LOGIN_forget_password")}
            </Text>
          </Row>
          <VSpace length={110} />
          <InputWrapper fullWidth={true} label={t("LOGIN_email_label")}>
            <InputBase value={email} placeholder={t("LOGIN_email")} onChange={handleChange} />
          </InputWrapper>
          <VSpace length={8} />
          <ButtonBase
            label={t("TEXT_COMMON_confirm")}
            size="21px"
            weight="500"
            fullWidth={true}
            border="transparent"
            onClick={handleForgetPasswordClick}
          />
        </>
      )}

      <Modal {...modalConfig}>
        <Text size="18px" weight="400" margin="auto 0">
          {t("LOGIN_modal_warning")}
        </Text>
      </Modal>
    </>
  )
}

export default ForgetPassword
