import { useEffect, useState } from "react"
import { t } from "i18next"
import { useDispatch, useSelector } from "react-redux"
import Router from "next/router"
import { postLogin, postOTP } from "@slices/auth"
import { User } from "@selector"

import { InputBase, InputWrapper } from "@components/inputs"
import { ImgBase } from "@components/images"
import { VSpace, HSpace, Text, Row } from "@layout"
import { ButtonBase } from "@components/buttons"
import { Modal } from "@components/modals"
import { Button } from "@mui/material"

const OTP = () => {
  const [otp, setOtp] = useState("")
  const [errorMessage, setErrorMessage] = useState()
  const [timer, setTimer] = useState(60)
  const dispatch = useDispatch()
  const user = useSelector(User)

  useEffect(() => {
    if (!user) {
      Router.push({ pathname: "/login" })
    }
  }, [])

  useEffect(() => {
    let timerId
    if (timer > 0) {
      timerId = setTimeout(() => {
        setTimer((pre) => pre - 1)
      }, 1000)
    }
    return () => clearTimeout(timerId)
  }, [timer])
  const handleChange = (type, { key, target }) => {
    if (type === "press") {
      if (key === "Enter") handleLogin()
    } else {
      setOtp(target.value)
    }
  }
  const handleLogin = async () => {
    dispatch(postLogin({ ...user, otp }))
      .unwrap()
      .then(() => Router.push({ pathname: "/" }))
      .catch(({ data }) => setErrorMessage(data?.message ?? "LOGIN_modal_warning"))
  }
  const handleResend = async () => {
    dispatch(postOTP(user))
      .unwrap()
      .then(() => {
        setTimer(60)
      })
      .catch(({ data }) => {
        setErrorMessage(t(data?.message) ?? "Error")
      })
  }

  const modalConfig = {
    buttonList: [{ label: t("TEXT_COMMON_confirm"), onClick: () => setErrorMessage() }],
    onClose: () => setErrorMessage(),
    open: Boolean(errorMessage)
  }
  return (
    <>
      <Row>
        <ImgBase src="ant-design-question-circle-outlined" alt="forget password" width={66} height={66} />
        <HSpace length={14} />
        <Text size="28px" weight="500">
          {t("LOGIN_Lable_otp")}
        </Text>
      </Row>
      <VSpace length={110} />
      <InputWrapper fullWidth={true} label={t("LOGIN_Lable_otp_enter")}>
        <InputBase
          value={otp}
          placeholder={t("LOGIN_Lable_otp")}
          onChange={(event) => handleChange("otp", event)}
          onKeyDown={(event) => handleChange("press", event)}
        />
      </InputWrapper>
      <VSpace length={30} />
      <ButtonBase
        label={t("TEXT_COMMON_confirm")}
        size="21px"
        weight="500"
        fullWidth
        border="transparent"
        onClick={handleLogin}
      />
      <VSpace length={30} />
      <Button
        variant={timer ? "contained" : "outlined"}
        py={1.5}
        sx={{
          fontSize: "21px",
          weight: "500",
          borderRadius: "8px",
          color: (theme) => `${theme.palette.text[timer ? "white" : "yellow"]} !important`
        }}
        fullWidth
        disabled={timer > 0}
        onClick={handleResend}
      >
        {timer ? t("LOGIN_Lable_resend_second", { n: timer }) : t("LOGIN_Lable_resend")}
      </Button>

      <Modal {...modalConfig}>
        <Text size="18px" weight="400" margin="auto 0">
          {t(errorMessage)}
        </Text>
      </Modal>
    </>
  )
}

export default OTP
