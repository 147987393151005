import { styled } from "@mui/styles"

export const Container = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  minHeight: "100vh"
}))
